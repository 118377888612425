






























.pre-cart-actions {
  //background: rgba(0,0,0,.5);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 12px;
  > div {
    flex: 1;
  }
}

.pre-cart-action-button {
  > button {
    margin: 8px;
    padding: 12px 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .pre-cart-action-button {
    > button {
      font-size: 12.5px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .pre-cart-action-button {
    margin: 0;
    > button {
      font-size: 12.5px !important;
      margin: 0 !important;
      width: 100% !important;
      padding: 10px 5px !important;
    }
  }
}

@media screen and (max-width: 320px) {

  .pre-cart-action-button {
    > button {
      padding: 8px 5px !important;
    }
  }
}
